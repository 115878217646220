import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo-white.png'
import facebook from '../img/social/fb.png'
import instagram from '../img/social/ig.png'

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
      <Container>
        <Grid container spacing={3}>

          <Grid item xs={12} lg={4}>
            <div className="footer-logo">
              <img
                src={logo}
                alt="Kvarntäkt20"
              />
            </div>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} lg={8}>
              <div className="menu-wrapper">
                <ul className="menu-list">
                  <li>
                    <Link to="/" className="navbar-item">
                      Hem
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/kurser">
                      Kurser
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/event">
                      Event
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/blogg">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/kontakt">
                      Kontakt
                    </Link>
                  </li>
                  <li>
                    <a
                      className="navbar-item"
                      href="/admin/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Admin
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <h4 className="footer-title">Följ oss på sociala medier!</h4>
            <div className="footer-socials">
              <a title="facebook" href="https://www.facebook.com/kvarntakt20.se/">
                <img
                  src={facebook}
                  alt="Facebook"
                />
              </a>
              <a title="instagram" href="https://www.instagram.com/kvarntakt20/">
                <img
                  src={instagram}
                  alt="Instagram"
                />
              </a>
            </div>
          </Grid>
        </Grid>
        </Container>
      </footer>
    )
  }
}

export default Footer
