import React from 'react'
import Image from '../img/divider.jpg'

const Divider = (
    <div className="divider-wrapper">
        <div className="divider" style={{backgroundImage: `url(${Image})`}}>
            <div className="fader">

            </div>
        </div>
    </div>
)

export default Divider
