import React from 'react'
import { Link } from 'gatsby'
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container'

import logo from '../img/logo.png'
import Divider from  '../components/ImageDivider'
import Icon from '@material-ui/core/Icon';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      position: "800"
    }
  }

  handleClick = () => {
  }
  
  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
              position: "0"
            })
          : this.setState({
              navBarActiveClass: '',
              position: "800"
            })
      }
    )

  }

  render() {

    let mobStyle = {
      transform: `translate3d(${this.state.position}px, 0 ,0)`
    }
    return (
      <React.Fragment>
      <nav className="navbar">
        <Container className="navbar-container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Kaldi" style={{ width: '250px' }} />
            </Link>
          </div>

          <Hidden mdUp>
            <div className="nav-hamburger" onClick={this.toggleHamburger}> 
              <Icon style={{color: '#444', fontSize: '35px'}}>{this.state.active ? 'close' : 'menu'} </Icon>
            </div>
          </Hidden>
          <Hidden smDown>
          <div className="nav-menu">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/kurser">
                  Kurser
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/personlig-utveckling">
                  Personlig utveckling
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blogg">
                  Blogg
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/event">
                 Event
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/kontakt">
                 Kontakt
               </Link>
              </li>
             </ul>
          </div>
        </Hidden>
        </Container>
      </nav>

      <Hidden mdUp>
        <div 
        className={`nav-mobile-wrapper ${this.state.navBarActiveClass}`}
        style={mobStyle}
        
        >
            <div className="nav-mobile">
              <ul>
                <Link className="navbar-item" to="/kurser">
                  <h2>Kurser</h2>
                </Link>
                <hr className="link-divider"/>
                <Link className="navbar-item" to="/personlig-utveckling">
                  <h2>Personlig utveckling</h2>
                </Link>
                <hr className="link-divider"/>
                <Link className="navbar-item" to="/event">
                  <h2>Event</h2>
                </Link>
                <hr className="link-divider"/>
                <Link className="navbar-item" to="/blogg">
                  <h2>Blogg</h2>
                </Link>
                <hr className="link-divider"/>
                <Link className="navbar-item" to="/kontakt">
                  <h2>Kontakt</h2>
                </Link>
              </ul>
              
            </div>
          </div>
      </Hidden>
      <Hidden mdUp>
        <div onClick={this.toggleHamburger} className={`nav-fade ${this.state.navBarActiveClass}`}>

        </div>
      </Hidden>
        {Divider}
      </React.Fragment>
    )
  }
}

export default Navbar
